import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { colorPalette } from "../colors";
import PropTypes from "prop-types";
import magazaImg from "../../images/dc-magaza.png";

const boxSize = 17;
const styles = (theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    alignItems: "top",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },

  content: {
    marginTop: `-${boxSize}vw`,

    "& h2": {
      fontSize: "calc(2vw + 2vh)",
      fontWeight: 800,
      display: "inline-block",
      marginBlockStart: "0.3vw",
    },

    "& p": {
      fontWeight: 300,
      fontSize: "calc(1.2vw + 1.2vh)",
    },

    "& span": {
      width: "40%",
      float: "right",
      color: colorPalette.blue,
      fontSize: "calc(4vw + 4vh)",
      fontWeight: 500,
      borderBottom: `0.4vw solid ${colorPalette.orange}`,
    },
  },

  box: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    position: "relative",
    zIndex: -4,
    top: `4vw`,
    content: '""',
    border: `calc(0.3vw + 0.3vh) solid #f2f3f4`,
    width: `${boxSize * 1.2}vw`,
    height: `${boxSize}vw`,
  },

  boxLeft: {
    left: "-4vw",
  },
  boxRight: {
    right: `-${boxSize * 1.2}vw`,
  },

  imgContainer: {
    textAlign: "center",
    overflow: "visible",
    "& img": {
      height: "100%",
      width: "100%",
      objectFit: "cover",
    },
  },

  exp: {
    zIndex: 1,
    paddingTop: "5em",
    paddingBottom: "5em",
  },

  left: {
    textAlign: "left",
    marginLeft: "1vw",
    paddingRight: "8vw",

    "& span": {
      float: "left",
    },

    "@media (max-width: 800px)": {},
    "@media (min-width: 800px)": {
      order: 2,
    },
  },

  right: {
    textAlign: "right",
    marginRight: "1vw",
    paddingLeft: "8vw",
  },
});

const VisionColumn = ({ order, imageSrc, header, text, classes }) => {
  const textFirst = order % 2 === 1;
  return (
    <div className={classes.root}>
      <div className={`${classes.exp} ${textFirst ? classes.right : classes.left}`}>
        <div className={`${classes.box} ${textFirst ? classes.boxRight : classes.boxLeft}`} />
        <div className={classes.content}>
          <span>{order}</span>

          <h2>{header}</h2>
          <p>{text}</p>
        </div>
      </div>
      <div className={`${classes.imgContainer}`}>
        <img src={imageSrc} alt="" />
      </div>
    </div>
  );
};

VisionColumn.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
  textFirs: PropTypes.bool,
  imageSrc: PropTypes.object,
  order: PropTypes.number,
};

VisionColumn.defaultProps = {
  imageSrc: magazaImg,
};

export default withStyles(styles)(VisionColumn);
