import React from "react";
import { withStyles } from "@material-ui/core";
import SubPageBase from "../../components/SubPageBase";
import VisionColumn from "../../components/bizkimiz/VisionColumn";
import { colorPalette } from "../../components/colors";
import img from "../../images/vizyon.png";
import img2 from "../../images/biz/vizyonumuz_vizyon2.jpg";
import img3 from "../../images/biz/deneyim3.png";
import img4 from "../../images/biz/vizyonumuz_vizyon4.jpg";
import img5 from "../../images/biz/vizyonumuz_vizyon5.jpg";

const styles = (theme) => ({
  root: {
    fontSize: "1.5em",
    backgroundColor: "#fff",
  },
  heading: {
    paddingLeft: "20%",
    padding: "calc(3vh + 3vw)",

    "& h3": {
      marginBlockStart: 0,
      marginBlockEnd: 0,
      fontSize: "calc(2vh + 2vw)",
      fontWeight: "700",
    },
  },

  box: {
    background: "#transparent",
    position: "absolute",
    left: "calc(5vw + 5vh)",
    zIndex: -4,
    content: '""',
    [theme.breakpoints.down("xs")]: {
      display: "none",
      top: 0,
    },
    width: `24vw`,
    height: `20vw`,
    "&:before": {
      position: "absolute",
      content: '""',
      zIndex: "-1",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      borderTopColor: "#f2f3f4",
      borderRightColor: "#f2f3f4",
      border: `1vw solid transparent`,
      transition: "width 0.1s ease-out, height 0.1s ease-out 0.1s",
    },
    "&:after": {
      position: "absolute",
      content: '""',
      zIndex: "-1",
      width: "100%",
      height: "100%",
      bottom: "0",
      right: "0",
      borderBottomColor: "#f2f3f4",
      borderLeftColor: "#f2f3f4",
      border: `1vw solid transparent`,
      transition: "border-color 0s ease-out 0.2s, width 0.1s ease-out 0.2s, height 0.1s ease-out 0.3s",
    },
  },
});

const IndexPage = ({ classes }) => (
  <SubPageBase title={"VİZYONUMUZ"} img={img} className={classes.root}>
    <div className={classes.heading}>
      <div className={classes.box} />
      <h3
        style={{
          color: colorPalette.blue,
          marginTop: "3vw",
        }}
      >
        BİZİ NE FARKLI KILIYOR?
      </h3>
      <h3>
        Kullanıcı ve spor
        <br /> odaklı ürünler
      </h3>
    </div>
    <VisionColumn
      order={1}
      header="SEVDİĞİMİZ İŞİ YAPIYORUZ"
      text="Kendi yolunu çizen ve farklılıklarımızla gelişen 103.000 farklı Decathlonluyuz."
    />
    <VisionColumn
      order={2}
      header="EŞSİZ ÜRÜNLER YARATIYORUZ"
      text="Sportif müşterilerimizi her gün memnun etmek için, kendi ürünlerimizi kendimiz tasarlıyor ve üretiyoruz."
      imageSrc={img2}
    />
    <VisionColumn
      order={3}
      header="MAĞAZALARIMIZ EŞSİZ BİR DENEYİM ALANIDIR"
      text="Müşterilerimize en iyi spor deneyimini sunmak için spor dolu mağazalar açıyoruz."
      imageSrc={img3}
    />
    <VisionColumn
      order={4}
      header="BİZE İHTİYAÇ DUYULAN YERLERDEYİZ"
      text="Sporu herkese ulaşılabilir kılmak için maliyetlerimizi düşürüyoruz ve en iyi kalite fiyat oranlı ürünlerimizle ön plana çıkıyoruz."
      imageSrc={img4}
    />
    <VisionColumn
      order={5}
      header="İNSANLARA VE GEZEGENİMİZE SAHİP ÇIKIYORUZ"
      text='"Doğa olmadan spor olmaz" diyoruz ve gezegenimizi korumak için çalışıyoruz.'
      imageSrc={img5}
    />
  </SubPageBase>
);

export default withStyles(styles)(IndexPage);
